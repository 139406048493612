<template>
  <div id="new-role" class="d-flex h-100 d-flex align-items-center justify-content-center">
    <b-card no-body style="width: 400px">
      <b-card-header class="d-flex align-items-center" header-bg-variant="transparent"
        >Dodaj użytkownika
        <div class="ml-auto">
          <b-button
            class="ml-auto mr-2"
            variant="light"
            v-if="!isObjectEmpty(form)"
            @click="clearForm"
            >Wyczyść</b-button
          >

          <b-button class="text-white" @click="$router.push({ name: 'UsersList' })">{{
            $t("BACK")
          }}</b-button>
        </div>
      </b-card-header>
      <b-card-body>
        <b-form ref="newUserForm" @submit.prevent="newUserForm">
          <!--E-MAIL-->
          <b-form-group class="my-3" label-cols-lg="4" label-size="sm" :label="$t('TABLE.EMAIL')">
            <b-form-input
              v-model.lazy="$v.form.email.$model"
              :placeholder="'E-mail'"
              aria-describedby="formNewUserEmailFeedback"
              :state="validateState('email')"
            ></b-form-input>
            <b-form-invalid-feedback id="formNewUserEmailFeedback">
              Wartość musi być mailem
            </b-form-invalid-feedback>
          </b-form-group>

          <!--PASSWORD-->
          <b-form-group class="my-3" label-cols-lg="4" label-size="sm" :label="$t('PASSWORD')">
            <b-form-input
              v-model="$v.form.password.$model"
              ref="password"
              :placeholder="$t('PASSWORD')"
              type="password"
              aria-describedby="formNewUserPasswordFeedback"
              :state="validateState('password')"
            ></b-form-input>
            <b-form-invalid-feedback id="formNewUserPlainPasswordFeedback">
              <div v-if="!$v.form.password.sameAs">Hasła musza być takie same</div>
              <div v-if="!$v.form.password.minLength">Minimalna długość hasła to: 8</div>
              <div v-if="!$v.form.password.containsNumber">
                Hasło musi mieć chociaż jedną liczbę.
              </div>
              <div v-if="!$v.form.password.containsUppercase">
                Hasło musi posiadać wielką literę
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!--PASSWORD PLAIN-->
          <b-form-group
            class="my-3"
            label-cols-lg="4"
            label-size="sm"
            :label="$t('PASSWORD_PLAIN')"
          >
            <b-form-input
              v-model="$v.form.plainPassword.$model"
              :placeholder="$t('PASSWORD_PLAIN')"
              type="password"
              aria-describedby="formNewUserPlainPasswordFeedback"
              :state="validateState('plainPassword')"
            ></b-form-input>
            <b-form-invalid-feedback id="formNewUserPlainPasswordFeedback">
              <div v-if="!$v.form.plainPassword.sameAs">Hasła musza być takie same</div>
            </b-form-invalid-feedback>
          </b-form-group>
          <!--FIRST NAME-->
          <b-form-group class="my-3" label-cols-lg="4" label-size="sm" :label="$t('FIRST_NAME')">
            <b-form-input
              v-model="$v.form.firstName.$model"
              :placeholder="$t('FIRST_NAME')"
              aria-describedby="formNewUserFirstNameFeedback"
              :state="validateState('firstName')"
            ></b-form-input>
            <b-form-invalid-feedback id="formNewUserFirstNameFeedback">
              Imię jest wymagane
            </b-form-invalid-feedback>
          </b-form-group>
          <!--LAST NAME-->
          <b-form-group class="my-3" label-cols-lg="4" label-size="sm" :label="$t('LAST_NAME')">
            <b-form-input
              v-model="$v.form.lastName.$model"
              :placeholder="$t('LAST_NAME')"
              aria-describedby="formNewUserLastNameFeedback"
              :state="validateState('lastName')"
            ></b-form-input>
            <b-form-invalid-feedback id="formNewUserLastNameFeedback">
              Nazwisko jest wymagane
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="buttons w-100 mt-2">
            <b-button
              type="submit"
              variant="primary"
              class="ml-auto d-block text-white"
              :class="{ disabled: $v.form.$invalid }"
            >
              {{ $t("ADD") }}
            </b-button>
          </div>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
// eslint-disable-next-line
import { required, sameAs, email, minLength } from "vuelidate/lib/validators";
import { NEW_USER } from "@/core/store/Users/users.module";

export default {
  name: "newUser",
  components: {},
  data() {
    return {
      form: {
        email: null,
        password: null,
        plainPassword: null,
        firstName: null,
        lastName: null,
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        containsUppercase(value) {
          return /[A-Z]/.test(value);
        },
        containsNumber(value) {
          return /[1-9]/.test(value);
        },
        minLength: minLength(8),
        sameAs: sameAs(function () {
          return this.form.plainPassword;
        }),
      },
      plainPassword: {
        sameAs: sameAs(function () {
          return this.form.password;
        }),
      },
      firstName: {
        required,
      },
      lastName: {
        required,
      },
    },
  },
  methods: {
    validateState(name, notForm) {
      const { $dirty, $error } = notForm ? this.$v[name] : this.$v.form[name];
      return $dirty ? !$error : null;
    },
    clearForm() {
      this.$refs.newUserForm.reset();
      // eslint-disable-next-line no-return-assign
      Object.keys(this.form).some((i) => (this.form[i] = null));
      this.password = null;
    },
    newUserForm() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$store
        .dispatch(NEW_USER, this.form)
        .then(() => {
          this.toastSuccess("Użytkownik stworzony pomyślnie", "Sukces");
          this.clearForm();
          this.$nextTick(() => {
            this.$v.form.$reset();
            this.$v.form.password.$reset();
          });
        })
        .catch((err) => {
          this.toastError("Error", err);
        });
    },
  },
};
</script>
